class ClientError extends Error {
	constructor(message, name, errorId) {
		super(message);

		this.name = name || 'Client Error';
		this.message = message;
		this.errorId = errorId;
		this.date = new Date();
		this.url = location.href;
	}

	toJSON() {
		return {
			...this,
			message: this.message
		};
	}
}


class ValidationError extends ClientError {
	constructor(message, field, details, errorId) {
		super(message, 'Validation Error', errorId);

		this.field = field;
		this.details = details;
	}
}


Ingtech.Error = ClientError;
Ingtech.ValidationError = ValidationError;