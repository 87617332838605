toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": true,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "6000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

jQuery.validator.addMethod("notEqual", function (v, e, p) {
  return this.optional(e) || v != p;
}, "Please specify a different value");

jQuery.each(["put", "delete"], function (i, method) {
  jQuery[method] = function (url, data, callback, type) {

    // Shift arguments if data argument was omitted
    if (jQuery.isFunction(data)) {
      type = type || callback;
      callback = data;
      data = undefined;
    }

    // The url can be an options object (which then must have .url)
    return jQuery.ajax(jQuery.extend({
      url: url,
      type: method,
      dataType: type,
      data: data,
      success: callback
    }, jQuery.isPlainObject(url) && url));
  };
});


function fireToaster(message) {
  if (message.error) {
    toastr.error(message.error, i18n.__ERROR).css("width", "400px");
  }
  if (message.success) {
    toastr.success(message.success, i18n.__CONFIRMATION).css("width", "400px");
  }
  if (message.info) {
    toastr.info(message.info, i18n.__WARNING).css("width", "400px");
  }
}

// Ingtech.cookie = new Ingtech.CookieManager('ingtech').cookie();

const TOASTR_TYPE = [
  ['success', 'CONFIRMATION'],
  ['error', 'ERROR'],
  ['info', 'LOADING'],
  ['warning', 'WARNING']
];

toastr.next = {};
TOASTR_TYPE.forEach(([type, defaultTitle]) => {
  toastr.next[type] = (message, title = defaultTitle, options = {}) => {
    Ingtech.cookie.messages = Ingtech.cookie.messages.concat([{ type, message, title, options }]);
  };
});

window.onunload = function (event) {
  // event.preventDefault();

  // $.get('/disconnect', (status, data) => {
  //   return null;
  // });

  // let targetHost = new URL(e.target.URL).hostname;
  //
  // if (targetHost != window.location.host) {
  //   return "Project is unsaved!!!";
  // }
  // else {
  //   return null;
  // }
};

$(document).ready(function () {
  if (typeof message !== "undefined") {
    fireToaster(message);
  }

  let cookieMessages = Ingtech.cookie.messages || [];
  Ingtech.cookie.messages = [];

  for (let message of cookieMessages.concat(Ingtech.messages || [])) {
    let text = i18n.get(message.message) || message.message;
    let title = i18n.get(message.title) || message.title;
    let option = message.options || {};

    if (option.href) {
      option.onclick = function () {
        location.href = option.href;
      };
    }

    toastr[message.type](text, title, message.options);
  }


  setTimeout(() => {
    $('.applyBtn').removeClass('btn-success').addClass('btn-primary');
  });
});

// $.fn.select2.defaults.set( "theme", "bootstrap" );

$('[data-toggle="tooltip"]').tooltip();
$('[data-toggle="popover"]').popover();
$('[data-toggle="select2"]').each(function () {
  let $this = $(this);

  let hidden = $this.is(':hidden');

  let options = {
    language: language
  };

  if ($this.data('ajax')) options.ajax = { url: $this.data('ajax'), dataType: 'json', delay: 200 };

  let select = $this.select2(options);

  if (hidden) select.next().hide();
});

$('body').on('show.bs.tooltip', async () => {
  let tooltips = $('.tooltip[role=tooltip]');
  setTimeout(() => {

    tooltips.remove();
  }, 250);
});



$(".input-file-profile").before(
  function () {
    if (!$(this).prev().hasClass('input-ghost')) {
      let that = this;
      let preview = $(this).next();
      let previewImg = $('#input-preview-profile');

      var element = $(`<input type="file" class="input-ghost" style="visibility:hidden; height:0" accept=".jpg,.png,.jpeg">`);
      element.attr("name", $(this).attr("name"));
      $(this).removeAttr("name");

      element.change(function () {
        if (this.files && this.files[0] && new Set(['image/jpeg', 'image/png']).has(this.files[0].type)) {
          var reader = new FileReader();

          reader.onload = function (e) {
            previewImg.attr('src', e.target.result);
            $('#editProfilePicture').hide();

            previewImg.croppie({
              boundary: { width: 150, height: 150 },
              viewport: { type: 'circle' }
            });

            preview.show();
          };

          reader.readAsDataURL(this.files[0]);
        } else {
          this.value = this.defaultValue;

          $(that).show();
          preview.hide();
        }
      });

      $(this).find("button.btn-choose").click(function () {
        element.click();
      });

      $(this).find("button.btn-reset").click(function () {
        element.val(null);
        $(this).parents(".input-file-profile").find('input').val('');
      });
      $(this).find('input').css("cursor", "pointer");
      $(this).find('input').mousedown(function () {
        $(this).parents('.input-file-profile').prev().click();
        return false;
      });

      previewImg.click(function () {
        // element.click();
      });

      $(this).find('.input-group-addon').css("cursor", "pointer").click(function () {
        element.click();
      });

      return element;
    }
  }
);

$(".input-file").before(
  function () {
    if (!$(this).prev().hasClass('input-ghost')) {
      let that = this;
      let preview = $(this).next();
      let previewImg = $(this).next().find('img');

      var element = $(`<input type="file" class="input-ghost" style="visibility:hidden; height:0" accept=".jpg,.png,.jpeg">`);
      element.attr("name", $(this).attr("name"));
      $(this).removeAttr("name");

      element.change(function () {
        if (this.files && this.files[0] && new Set(['image/jpeg', 'image/png']).has(this.files[0].type)) {
          var reader = new FileReader();

          reader.onload = function (e) {
            previewImg.attr('src', e.target.result);

            $(that).hide();
            preview.show();
          };

          reader.readAsDataURL(this.files[0]);
        } else {
          this.value = this.defaultValue;

          $(that).show();
          preview.hide();
        }
      });
      $(this).find("button.btn-choose").click(function () {
        element.click();
      });
      $(this).find("button.btn-reset").click(function () {
        element.val(null);
        $(this).parents(".input-file").find('input').val('');
      });
      $(this).find('input').css("cursor", "pointer");
      $(this).find('input').mousedown(function () {
        $(this).parents('.input-file').prev().click();
        return false;
      });
      previewImg.click(function () {
        element.click();
      });

      $(this).find('.input-group-addon').css("cursor", "pointer").click(function () {
        element.click();
      });

      return element;
    }
  }
);

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = font;
  var metrics = context.measureText(text);

  return metrics.width;
}

$('.suffix').prev().filter('input').on('input change ready', function (ev) {
  let suffixElement = $(this).next().get(0);

  const width = getTextWidth(this.value, '14px Open Sans');
  suffixElement.style.left = width + 25 + 'px';

  $(this).next().toggle(!!this.value);
}).change();


/**
* Cache panel
* @type {*|HTMLElement}
*/
var $panel = $('.panel');

/**
* Collapse the panel once clicked on the collapse button
*/
$('.collapse-box').tooltip({
  title: function () {
    return $(this).hasClass('fa-chevron-up') ? i18n.__EXPEND_PANEL : i18n.__COLLAPSE_PANEL;
  },
  container: 'body'
});

$('.collapse-box').closest('header,h4').click(function (ev) {
  let $btn = $(this).find('.collapse-box');

  if ($btn.is(':visible') && this == ev.target) {
    $btn.click();
  }
});

$('.collapse-box').parent().find('.force-expand-box').click(function (ev) {
  let $btn = $(this).parent().find('.collapse-box');

  if ($btn.is(':visible')) {
    let newStyle = $btn.hasClass("icon-style");
    let $thisIcon = newStyle ? $btn.find('i') : $btn;
    let isOpened = newStyle ? $thisIcon.hasClass("icon-main-collapse") : $thisIcon.hasClass("fa-chevron-up");

    if (isOpened) {
      $btn.click();
    }
  }
});

$panel.on('click.PanelCollapse', '.collapse-box', function (e) {
  var $this = $(this), // clicked element
    $thisPanel = $(e.delegateTarget),
    $thisPanelHead = $this.closest('.panel-heading,h4'),
    $thisPanelBody = $thisPanelHead.next();

  let newStyle = $this.hasClass("icon-style");
  let $thisIcon = newStyle ? $this.find('i') : $this;
  let isOpened = newStyle ? $thisIcon.hasClass("icon-main-expand") : $thisIcon.hasClass("fa-chevron-down");

  $this.focusout().blur();
  $this.tooltip('hide');

  setTimeout(() => {
    if ($this.is(':hover')) $this.tooltip('show');
  }, 200);

  if (isOpened) {
    if (newStyle)
      $thisIcon.removeClass("icon-main-expand").addClass("icon-main-collapse");
    // else

    $this.removeClass("fa-chevron-down").addClass("fa-chevron-up");

    if (!$thisPanelHead.hasClass('keep-border')) $thisPanelHead.removeClass('panel-border');
    $thisPanelBody.hide();
  } else {
    if (newStyle)
      $thisIcon.removeClass("icon-main-collapse").addClass("icon-main-expand");
    // else

    $this.removeClass("fa-chevron-up").addClass("fa-chevron-down");

    if (!$thisPanelHead.hasClass('no-border')) $thisPanelHead.addClass('panel-border');
    $thisPanelBody.show();

    let $dataTables = $thisPanelBody.find('.dataTable');

    if ($dataTables.length > 0) {
      $dataTables.DataTable().draw();
      setTimeout(() => {
        $dataTables.DataTable().draw();
      }, 0);
    }
  }
});

/**
* Delete the panel once clicked on the close button
*/
$panel.on('click.PanelDestroy', '.close-box', function (e) {
  $(e.delegateTarget).remove();
});

/**
* Refresh the panel once clicked on the refresh button
*/
$panel.on('start.anim.PanelRefresh', function (e) {
  var $refreshBlock = $("<div class='refresh-block'><span class='refresh-loader'><i class='fa fa-spinner fa-spin'></i></span></div>");

  $refreshBlock.appendTo($(this));
});

$panel.on('stop.anim.PanelRefresh', function (e) {
  $(this).find('.refresh-block').remove();
});


$('.refresh-box').tooltip({
  title: i18n.__REFRESH_PANEL,
  container: 'body'
});

$panel.on('click.PanelRefresh', '.refresh-box', function (e) {
  $(this).focusout().blur();

  e.preventDefault();

  var $thisPanel = $(e.delegateTarget);

  $thisPanel.trigger('start.anim.PanelRefresh');

  let promise;

  promise = new Promise(resolve => {
    let finished = false;
    let resolved = false;

    $thisPanel.trigger('refresh', (now) => {
      resolved = true;

      if (now || finished) {
        resolve();
      }

      return promise;
    });

    setTimeout(() => {
      finished = true;

      if (resolved) {
        resolve();
      }
    }, 2);
  }).then(() => {
    $thisPanel.trigger('stop.anim.PanelRefresh');
  });
});

// // Fixed weird alignment when multiple modals
// let scrollBarPaddingForModal = 0;

// $('.modal').on('show.bs.modal', async function () {
//   scrollBarPaddingForModal = $('body').css('padding-right');
  
//   // C'est vraiment laid, mais ça fonctionne
//   for (let i = 0; i < 50; i++) {
//     await util.sleep();
//     $(this).css('padding-right', '0px');
//   }
// });

// $('.modal').on('hidden.bs.modal', function () {
//   if ($('.modal.in').length) {
//     $('body').toggleClass('modal-open', true);
//     $('body').css('padding-right', scrollBarPaddingForModal);
//   } else {
//     $('body').css('padding-right', '0px');
//   }
// });

/**
* Resize all textarea with .autosize class
*/
autosize($('.autosize'));

/**
* Initialize Nice Scroll with .niceScroll class
*/
$(".niceScroll").niceScroll({
  zindex: "10",
  cursorwidth: "8px",
  cursoropacitymax: 0.3,
  cursorcolor: "#000000",
  cursordragontouch: true,
  cursorborderradius: "10px",
  cursorborder: "2px solid transparent"
});



$(document).ready(() => {
  let $daterange = $('#daterange');

  if ($daterange.length > 0) {
    $('.ui-container').addClass('datepicker-active')
  }
})
