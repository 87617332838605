if (Ingtech._permissions) {

  Ingtech.PERMISSIONS = {
    NOT_ALLOWED: 0,
    OPTIONAL: 2,
    COMINGSOON: 3,
    ALLOWED: 4
  };



  Ingtech.permissionLevel = function (category, permission = 'main', module = false) {
    if (category.match(/[\.-]/)) {
      let parts = category.split(/[\.-]/);
      category = parts[0];
      permission = parts[1];
    }

    let moduleCategory = this._permissions.modules[category];
    if (!moduleCategory || moduleCategory.main == this.PERMISSIONS.NOT_ALLOWED)
      return this.PERMISSIONS.NOT_ALLOWED;

    if (module) {
      return moduleCategory[permission];
    }


    let permissionsCategory = this._permissions.permissions[category];
    if (!permissionsCategory || permissionsCategory.main == this.PERMISSIONS.NOT_ALLOWED)
      return this.PERMISSIONS.NOT_ALLOWED;

    return Math.min(moduleCategory[permission] || 0, permissionsCategory[permission] || 0);
  };

  Ingtech.hasAccess = function (category, permission = 'main') {
    return this.permissionLevel(category, permission) == this.PERMISSIONS.ALLOWED;
  };

  Ingtech.hasModule = function (category, permissions = 'main') {
    return this.permissionLevel(category, permissions, true) == this.PERMISSIONS.ALLOWED;
  };
}