const util = Ingtech.util;

if ($.fn.dataTable) {
  let parseButton = function (btnConf, api) {
    if (btnConf == 'divider' || btnConf.className == "divider" || btnConf.type == "divider") {
      if (btnConf.className == 'divider') btnConf.className = null;

      return $(`<li class="divider ${btnConf.className || ''}"></li>`);
    }

    else if (btnConf.type == 'submenu') {
      let submenu = $(`<li class="dropdown-submenu ${btnConf.className}">
        <a class="title" tabindex="-1" href="#">${btnConf.text}</a>
        <ul class="dropdown-menu">
          
        </ul>
      </li>`);

      let submenuMenu = submenu.children('.dropdown-menu');
      submenu.attr(btnConf.attr || {});

      for (let btnSubConf of btnConf.buttons || []) {
        let btn = parseButton(btnSubConf).appendTo(submenu.children('.dropdown-menu'));

        btn.click(() => {
          submenuMenu.hide();
          $(window).trigger($.Event('hide.submenu', { target: submenuMenu, relatedTarget: submenu }));
        });
      }


      let isOpened = false;
      let cd;

      let hoverIn = (e) => {
        if (submenu.hasClass('disabled')) return;

        if (!isOpened) {
          cd = setTimeout(() => {
            isOpened = true;
            cd = null;

            submenuMenu.show();
            $(window).trigger($.Event('show.submenu', { target: submenuMenu, relatedTarget: submenu }));
          }, 500);
        } else {
          if (cd) clearTimeout(cd);
        }
      };

      let hoverOut = (e) => {
        if (submenu.hasClass('disabled')) return;

        if (isOpened) {
          cd = setTimeout(() => {
            isOpened = false;
            cd = null;

            submenuMenu.hide();
            $(window).trigger($.Event('hide.submenu', { target: submenuMenu, relatedTarget: submenu }));
          }, 500);
        } else {
          if (cd) clearTimeout(cd);
        }
      };

      submenu.children('a.title').hover(hoverIn, hoverOut).click(e => {
        e.preventDefault();
        e.stopPropagation();

        if (submenu.hasClass('disabled')) return;

        submenuMenu.show();
        $(window).trigger($.Event('show.submenu', { target: submenuMenu, relatedTarget: submenu }));

        isOpened = submenuMenu.is(':visible');
        if (cd) clearTimeout(cd);
      });
      submenuMenu.hover(hoverIn, hoverOut);



      return submenu;
    }

    else {
      btnConf.tag = btnConf.tag || 'li';
      btnConf.href = btnConf.href || 'javascript:;';

      if (btnConf.preInit) {
        let override = btnConf.preInit(btnConf);
        if (override == false) return;
      }

      let btn = $(`<${btnConf.tag} class="${btnConf.className}"><a href="${btnConf.href}">${btnConf.text}</a></${btnConf.tag}>`);

      btn.attr(btnConf.attr || {});

      if (btnConf.init) btnConf.init(api, btn, btnConf);

      btn.on('click', function (ev) {
        if (!$(this).hasClass('disabled')) {
          if (btnConf.action) {
            btnConf.action.call(api, this, ev);

            ev.preventDefault();
          }
        } else {
          ev.stopPropagation();
          ev.preventDefault();
        }
      });

      return btn;
    }
  };

  $.fn.dataTable.Buttons.defaults.dom.collection.action.dropHtml = '';

  $.fn.dataTable.ext.buttons.dropDown = {
    init: (api, node, config) => {
      node.attr('data-toggle', 'dropdown');

      if (config.preInit) {
        config.preInit.call(api, config, node);
      }

      config.text = config.text + ' <span class="caret"></span>';
      node.html(config.text);

      setTimeout(() => {
        let div = $('<span class="dropdown"></span>').appendTo(node.parent());

        node.appendTo(div);
        node.html(config.text);

        let list = $(`<ul class="dropdown-menu" style="min-width:${div.width()}px;"></ul>`).appendTo(div);

        for (let btnConf of config.buttons) {
          let button = parseButton.call(list, btnConf, api);

          if (button) button.appendTo(list);
        }

        if (list.children().length == 0) {
          node.prop('disabled', true);
        }
      }, 0);
    },
    action: function (e, dt, node, config) {
      $(node).next().css('min-width', $(node).parent().width());
    }
  };


  $.fn.dataTable.ext.buttons.link = {
    tag: 'a',
    init: (dt, node, config) => $(node).off('click')
  };

  $.fn.dataTable.ext.errMode = 'none';

  $('body').on('error.dt', '.dataTable', function (e, settings, techNote, message) {
    console.error('An error has been reported by DataTables: ', message);

    // toastr.error(i18n._$DATA_TABLE_ERROR(techNote), i18n.__ERROR);
  });

  (function () {
    let Api = $.fn.dataTable.Api;

    // Source: https://datatables.net/plug-ins/filtering/type-based/accent-neutralise
    function removeAccents(data) {
      if (data.normalize) {
        // Use I18n API if avaiable to split characters and accents, then remove
        // the accents wholesale. Note that we use the original data as well as
        // the new to allow for searching of either form.
        return data + ' ' + data
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
      }

      return data;
    }

    Object.assign($.fn.DataTable.ext.type.search, {
      string: data => !data ? '' :
        typeof data === 'string' ? removeAccents(data) : data,
      locale: data => !data ? '' :
        typeof data === 'string' ? removeAccents(data) : data,
      html: data => !data ? '' :
        typeof data === 'string' ? removeAccents(data.replace(/<.*?>/g, '')) : data
    });



    Object.assign($.fn.dataTableExt.oSort, {
      'number-asc': (a, b) => (parseFloat(a) || 0) - (parseFloat(b) || 0),
      'number-desc': (a, b) => (parseFloat(b) || 0) - (parseFloat(a) || 0),
      'locale-asc': (a, b) => a.localeCompare(b, 'fr'),
      'locale-desc': (a, b) => b.localeCompare(a, 'fr'),
      'duration-asc': (a, b) => moment.duration(a) - moment.duration(b),
      'duration-desc': (a, b) => moment.duration(b) - moment.duration(a),
      'checkbox-asc': (a, b) => {
        return $(a).prop('checked') - $(b).prop('checked');
      },
      'checkbox-desc': (a, b) => $(b).prop('checked') - $(a).prop('checked'),
    });

    $.fn.dataTable.ext.order.checkbox = function (settings, col) {
      return this.api().column(col, { order: 'index' }).nodes().map(function (td, i) {
        return $('input', td).prop('checked') ? 0 : 1;
      });
    };

    Api.register('row().position()', function () {
      var that = this;
      let displayIdx = -1;

      this.iterator('row', function (ctx, rowIdx) {
        if (ctx.oScroller) {
          displayIdx = that
            .rows({ order: 'applied', search: 'applied' })
            .indexes()
            .indexOf(rowIdx);
        }
      });

      return displayIdx;
    });

    Api.register('row().offset()', function () {
      var that = this;

      let offset;

      this.iterator('row', function (ctx, rowIdx) {
        let row = that.row(rowIdx);
        let $row = $(row.node());
        let $table = $(row.table().node());
        let $body = $table.closest('.dataTables_scrollBody');

        if ($row.length == 0) return;

        let offsetTopInTable = $row.offset().top - $table.offset().top;
        let offsetTopInBody = offsetTopInTable - $body.scrollTop();

        let offsetLeftInTable = $row.offset().left - $table.offset().left;
        let offsetLeftInBody = offsetLeftInTable - $body.scrollLeft();

        offset = { top: offsetTopInBody, left: offsetLeftInBody };
      });

      return offset;
    });

    Api.register('row().isVisible()', function () {
      var that = this;
      let visible = false;

      this.iterator('row', function (ctx, rowIdx) {
        let row = that.row(rowIdx);
        let $row = $(row.node());
        let $table = $(row.table().node());
        let $body = $table.closest('.dataTables_scrollBody');

        let { top } = row.offset();
        let bodyHeight = $body.height();

        let rowHeight = $row.height() + (row.child() && row.child().height() || 0);

        visible = top > 0 && (top + rowHeight) < bodyHeight;
      });

      return visible;
    });


    Api.register('row().scrollTo()', function (fixTop = false, animationTime = 500) {
      var that = this;

      if (typeof fixTop == 'number') {
        animationTime = fixTop;
        fixTop = false;
      }

      this.iterator('row', function (ctx, rowIdx) {
        let row = that.row(rowIdx);
        let $row = $(row.node());
        let $table = $(row.table().node());
        let $body = $table.closest('.dataTables_scrollBody');

        if ($row.length == 0) return;

        let { top } = row.offset();
        let bodyHeight = $body[0].clientHeight;

        let rowHeight = $row.height() + (row.child() && row.child().height() || 0);

        if (top < 0 || fixTop) {
          $body.animate({
            scrollTop: $body.scrollTop() + top
          }, animationTime);
        } else if (top + rowHeight > bodyHeight) {
          $body.animate({
            scrollTop: $body.scrollTop() + (top + rowHeight - bodyHeight)
          }, animationTime);
        }
      });
    })


    Api.register('columnName()', function (name) {
      return this.column(`[name="${name}"]`);
    });

    /**
     * 
     * @param {import('datatables.net').Api<any>} context 
     * @param {import('datatables.net').ExportOptions} options
     * @returns 
     */
    async function prepareTableData(context, options = {}) {
      const columns = context.columns(options.columnSelector ?? ':not(.noVis):visible', options.columnModifier);
      let rows = context.rows(options.rowSelector, options.rowModifier || { selected: true });
      if (!options.rowModifier && rows.count() === 0) {
        rows = context.rows(options.rowSelector, { search: 'applied' });
      }
      
      const headerTitles = columns.header().toArray()
        .map(td => $(td).text().trim().replace(/\s+/g, ' '));

      const data = [];
      const promises = [];

      const $temp = $('<div>');

      for (let [i, rowId] of rows.indexes().toArray().entries()) {
        for (let [j, colId] of columns.indexes().toArray().entries()) {
          const cell = context.cell(rowId, colId);
          const displayValue = cell.render('display');

          try {
            $temp.html(displayValue);
          } catch (err) {
            $temp.html(displayValue.toString());
          }

          const content = $temp.find('[data-text]').attr('data-text')
            || $temp.find('[title]:not([data-info])').attr('title')
            || $temp.text().trim();

          data[i] = data[i] || [];
          data[i][j] = content;

          if (displayValue instanceof Promise) {
            displayValue.then(value => {
              data[i][j] = value;
            });
            promises.push(displayValue);
          }
        }
      }
      
      let dt = new Api(context);
      const footerContent = dt.footer.displayData();
      const footer = footerContent ? [] : null;

      if (footerContent) {
        for (let [i, colId] of columns.indexes().toArray().entries()) {
          const column = context.context[0].aoColumns[colId];
          const key = column.footerDate || column.name || column.data;

          if (footerContent[key]) {
            footer[i] = footerContent[key];
          }
        }
      }

      console.log(footer, footerContent);

      if (promises.length > 0) {
        await Promise.all(promises);
      }

      return { headerTitles, data, footer };
    }

    Api.register('rowFromData()', function (data) {
      return this.row((_, rowData) => rowData === data || rowData._original === data);
    });

    Api.register('exportCsv()', async function (filename, options) {
      toastr.info(i18n.__GENERATING_CSV, i18n.__LOADING);

      const { headerTitles, data } = await prepareTableData(this, {
        columnSelector: ':not(.noVis), .export-only',
        ...options
      });

      if (data.length === 0) {
        toastr.warning(i18n.__DATATABLE.emptyTable, i18n.__WARNING);
        return
      }

      const csvContent = util.arrayToCsv([headerTitles, ...data])

      var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) { // Pour IE
        navigator.msSaveBlob(blob, filename + '.csv');
      } else {
        var link = document.createElement('a');
        if (link.download !== undefined) {
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename + '.csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    });

    Api.register('exportPdf', async function (filename, title, subtitle, options = {}) {
      toastr.info(i18n.__GENERATING_PDF, i18n.__LOADING);

      const { headerTitles, data, footer } = await prepareTableData(this, options);

      if (data.length === 0) {
        toastr.warning(i18n.__DATATABLE.emptyTable, i18n.__WARNING);
        return
      }

      try {
        const response = await fetch(`/export/table/pdf/${filename}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ headerTitles, data, footer, title, subtitle }),
        });

        if (!response.ok) throw new Error('Network response was not ok.');

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${filename}_${moment().format('YYYY-MM-DD')}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error('Error:', error);
      }
    });

    Api.register('footer.add()', function (content) {
      const columnsOptions = this.settings()[0].aoColumns;
      this.context[0]._footerContent = content;
      this.context[0]._footerDisplay = {}

      for (let column of columnsOptions) {
        const key = column.footerDate || column.name || column.data;
        const $footer = $(this.column(column.idx).footer());

        if (content[key] || column.footerRender instanceof Function) {
          const contentData = key ? content[key] : content;
          const data = column.footerRender instanceof Function
            ? column.footerRender(contentData, 'footer', content, { col: column.idx, settings: this.settings() })
            : contentData;

          $footer.html(data);
          this.context[0]._footerDisplay[key] = data;
        } else {
          // $footer.html('');
        }
      }
    });

    Api.register('footer.data()', function () {
      return this.context[0]._footerContent;
    });

    Api.register('footer.displayData()', function () {
      return this.context[0]._footerDisplay;
    });

    
    Api.register('footer.widthCorrection()', function () {
      const settings = this.settings()[0];

      const scrollBody = $(settings.nScrollBody);
      const scrollFootInner = $(settings.nScrollFoot).children().first();

      scrollFootInner.css("box-sizing", "content-box");
      scrollFootInner.css("padding-right", scrollBody.hasScrollBar() ? scrollBody.getScrollBarWidth() : 0);
    });


    $.fn.dataTable.ext.contextmenu = {};
    $.fn.dataTable.ext.contextmenu.onclick = (row, cb) => {
      $(row).find('.btn-menu').click(function (ev) {
        ev.preventDefault();
        ev.stopPropagation();

        $(ev.target).trigger('contextmenu', [ev.pageX, ev.pageY]);

        if (typeof cb == 'function') cb(ev);
      });
    };

    $.fn.dataTable.ext.contextmenu.column = () => {
      return {
        name: 'contextmenu',
        orderable: false,
        width: '1%',
        render: () => `<a href="#" class="icon-button btn-menu" style="margin-left: 5px;">${util.iconDom('main', 'menu', 'xs')}</a>`,
        className: 'noVis align-right',
      };
    };

    $(document).on('draw.dt', function (e, ctx, row, data) {
      let dt = new Api(ctx);

      dt.rows().nodes().each(function (node) {
        $(node).attr('role', 'row');
      });
    });

    $(document).on('preInit.dt.dtContextmenu', function (e, ctx) {
      /** @type {import('datatables.net').Config} */
      let settings = ctx.oInit;

      if (settings.contextmenu) {
        let dt = new Api(ctx);

        settings.contextmenu.dt = dt;
        settings.contextmenu.minWidth = settings.contextmenu.minWidth || 120;

        let show = settings.contextmenu.show;

        settings.contextmenu.show = function (ev, dropdownMenu, target, config) {
          let node = $(target).closest('[role="row"]');
          let row = dt.row(node);

          if (row.length > 0) {
            config.row = row;

            if (show) show.call(this, ev, dropdownMenu, target, config);
          } else {
            ev.preventDefault();
          }
        };

        for (let button of settings.contextmenu.buttons || []) {
          if (button.click instanceof Function) {
            let click = button.click;

            button.click = function (ev, button, dropdownMenu, config) {
              return click.call(this, config.row, ev, button, dropdownMenu, config);
            };
          }

          if (button.show instanceof Function) {
            let show = button.show;

            button.show = function (ev, button, dropdownMenu, config) {
              return show.call(this, config.row, ev, button, dropdownMenu, config);
            };
          }

          if (button.config instanceof Function) {
            let btnConfig = button.config;

            button.config = function (ev, button, dropdownMenu, config) {
              return btnConfig.call(this, config.row, ev, button, dropdownMenu, config);
            };
          }
        }

        $(dt.table().node()).appendAdvDropdown(settings.contextmenu);
      }
    });


    var __re_column_selector = /^([^:]+):(name|visIdx|visible)$/;

    $(document).on('options.dt', (e, options) => {
      for (let column of options.columns || []) {
        if (!column.name && typeof column.data == 'string') {
          column.name = column.data;
        }

        if (column.exportOnly) {
          column.visible = false;
          column.className = column.className + ' noVis export-only';
        }

        const oldRender = column.render;

        column.render = function (data, type, row, meta) {

          if (type == 'display') {
            if (!(data instanceof Promise)) {
              return oldRender ? oldRender(data, type, row, meta) : data;
            }

            const dt = new Api(meta.settings);

            const randomId = Math.random().toString(36).substring(7);
            const $temp = $(`<span id=${randomId}>`);
            $temp.html('<i class="fa fa-spinner fa-spin"></i>');


            data.then(async value => {
              const data = oldRender ? oldRender(value, type, row, meta) : value;

              dt.cell(meta.row, meta.col).data(data);
              // dt.draw(false);

              $(`#${randomId}`).replaceWith(data);

              if (await util.buffer()) {
                dt.draw(false);
              }

              return data;
            });

            data.toString = () => {
              return $temp[0].outerHTML;
            }

            return data;
          }

          return oldRender ? oldRender(data, type, row, meta) : data;
        }
      }

      if (options.order instanceof Array && options.order.length > 0 && typeof options.order[0] != 'object') {
        options.order = [options.order];
      }

      if (options.order instanceof Array) {
        for (let order of options.order || []) {
          if (!(order instanceof Array)) continue;
          let [col, dir] = order;

          if (typeof col == 'string') {
            let match = col.match(__re_column_selector);

            if (match) {
              let [_, colName, selector] = match;

              let colIdx = 0;

              switch (selector) {
                case 'name':
                  colIdx = options.columns.findIndex(c => c.name == colName);
                  break;
                case 'visIdx':
                case 'visible':
                  let col = options.columns.filter(c => c.visible !== false)[parseInt(colName)];
                  colIdx = options.columns.indexOf(col);
                  break;
              }

              order[0] = colIdx;
            }

          }
        }
      }
    });

    const DataTableFn = jQuery.fn.DataTable;
    jQuery.fn.DataTable = function (options, ...args) {
      const e = $.Event('options.dt');

      if (options instanceof Object) {
        $(this).trigger(e, [options]);
      }

      const dt = DataTableFn.call(this, options, ...args);

      return dt;
    }
  }());

  $.fn.dataTable.Scroller.prototype._parseHeight = function (cssHeight) {
    return $(this.dom.scroller).height();
  }
}