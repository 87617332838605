
window.Colors = {
    "brand-white": "#ffffff",
    "brand-black": "#2c2c2c",

    "brand-light": "#eaeaea",
    "brand-dark": "#626262",

    "brand-default": "#eaeaea",
    "brand-primary": "#e7662e",
    "brand-info": "#4aa9e9",
    "brand-success": "#23b9a9",
    "brand-warning": "#eac459",
    "brand-danger": "#ff6c60",

    "shade0": "#000000",
    "shade1": "#0d0d0d",
    "shade2": "#262626",
    "shade3": "#404040",
    "shade4": "#595959",
    "shade5": "#616161",
    "shade6": "#686868",
    "shade7": "#e0e0e0",
    "shade8": "#ffffff",
    "white": "#ffffff",
    "blue": "#287aa9",
    "electric-blue": "#0079dc",
    "dark": "#424242",
    "light": "#898989",
    "grey": "#898989",
    "yellow": "#f2bc44",
    "light-yellow": "#f7e7a3",
    "moderate-yellow": "#edc730",
    "off": "#424242",
    "d": "#8bc34a",
    "green": "#8bc34a",
    "light-green": "#c5e0b4",
    "approved-green": "#608731",
    "certified-green": "#91c253",
    "orange": "#ff9800",
    "on": "#ff9800",
    "purple": "#673ab7",
    "sb": "#673ab7",
    "red": "#E60026",
    "critical-red": "#f3625f",
    "high-red": "#f57e7b"
}

