$(document).ready(async () => {
  // Online status dot on topbar
  // if (!Ingtech.online) return;

  // let updateOnlineStatus = async () => {

  //   try {

  //     await $.get('/json/ping');

  //     Ingtech.online = true;
  //   } catch (err) {
  //     Ingtech.online = false;
  //   } finally {
  //     $('#status-dot-top').toggleClass('dot-green', Ingtech.online).toggleClass('dot-red', !Ingtech.online);
  //   }
  // };

  // Ingtech.socket.on('connect', updateOnlineStatus);
  // Ingtech.socket.on('disconnect', updateOnlineStatus);

  // $('#status-dot-top').tooltip({
  //   title: function () {
  //     return Ingtech.online ? i18n.__ONLINE : i18n.__OFFLINE;
  //   },
  //   container: 'body'
  // });

  // updateOnlineStatus();
  // setInterval(updateOnlineStatus, 15000);


  function updateOnlineStatus(online) {
    Ingtech.online = online;

    $('#status-dot-top').toggleClass('dot-green', online).toggleClass('dot-red', !online);
  }


  Ingtech.page.on('user.connected', () => {
    updateOnlineStatus(true);
  });

  Ingtech.page.on('user.disconnected', () => {
    updateOnlineStatus(false);
  });

  Ingtech.page.on('user.unauthenticated', () => {
    let currentAccount = Ingtech.cookie.currentAccount;

    let disconnectionToastr = toastr.error(i18n.__SESSION_EXPIRED_RECONNECT, i18n.__ERROR, {
      timeOut: 0, extendedTimeOut: 0, closeButton: false, onclick: () => {

        let reconnectWin = window.open(`/login?close-tab=true`, '_blank');
        reconnectWin.focus();
      }
    });


    let reconnectInterval = setInterval(() => {
      if (Ingtech.cookie.token) {
        clearInterval(reconnectInterval);

        if (currentAccount == Ingtech.cookie.currentAccount) {
          toastr.clear(disconnectionToastr);
          
          Ingtech.Broker.connect();
        } else {
          location.reload();
        }
      }
    }, 100);
  });
});
