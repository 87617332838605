$(document).ready(function() {
  if(location.href.indexOf('error') != -1) {
    toastr.error(i18n.__LOGIN_INCORRECT, i18n.__ERROR).css("width","400px");
  }

  $('button.selectAccount').click(function() {
    var id = $(this).val();
    var url = '/select-account';

    var params={};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str,key,value) {
      params[key] = value;
    });

    if(params['redirect'] && params['redirect'] != '/select-account') {
      url += '?redirect=' + params['redirect'];
    }

    $.ajax({
      url: url,
      type: 'post',
      data: {
        accountId: id
      },
      dataType: 'json',
      success: function(data) {
        window.location.href = data.url;
      }
    });
  });

  $('.sign-in-form').validate({
    rules: {
      username: "required",
      password: "required",
    },
    messages: {
      username: {
        required: i18n.__INPUT_REQUIRED
      },
      password: {
        required: i18n.__INPUT_REQUIRED
      }
    },
    submitHandler: function(form) {
      form.submit();
    }
  });

  $('.request-reset-password-form').validate({
    rules: {
      username: "required",
    },
    messages: {
      username: {
        required: i18n.__INPUT_REQUIRED
      }
    },
    submitHandler: function(form) {
      form.submit();
    }
  });

  $('.reset-password-form').validate({
    rules: {
      password: {
        required: true,
        minlength: 6,
      },
      confirmation: {
        equalTo: "#password"
      }
    },
    messages: {
      password: {
        required: i18n.__INPUT_REQUIRED,
        minlength: i18n.__PASSWORD_MIN_LENGTH
      },
      confirmation: {
        equalTo: i18n.__PASSWORD_EQUAL_TO
      }
    },
    submitHandler: function(form) {
      form.submit();
    }
  });

  $('.sign-up').click(function(ev) {
    toastr.error(i18n.__CONTACT_YOUR_ADMINISTRATOR, i18n.__ERROR).css("width","400px");
  });
});
