$.validator.setDefaults({
  highlight: function (element) {
    let elem = $(element);

    if (elem.hasClass("select2-offscreen")) {
      $("#s2id_" + elem.attr("id") + " ul").addClass('has-error');
    } else {
      elem.closest('.form-group').addClass('has-error');
    }
  },
  unhighlight: function (element) {
    let elem = $(element);

    if (elem.hasClass("select2-offscreen")) {
      $("#s2id_" + elem.attr("id") + " ul").removeClass('has-error');
    } else {
      let group = $(elem).closest('.form-group');

      setTimeout(() => {
        if (group.find('.help-block:visible').length == 0) {
          group.removeClass('has-error');
        }
      });
    }
  },
  errorElement: 'span',
  errorClass: 'help-block',
  errorPlacement: function (error, element) {
    if (error.text()) {
      if (element.attr('error-placement')) {
        element.closest(element.attr('error-placement')).append(error);
      } else if (element.parent('.input-group').length) {
        error.insertAfter(element.parent());
        // element.parent().append(error);
      } else {
        element.parent().append(error);
      }
    }
  }
});


$.validator.addMethod("match", function (value, element, params) {
  if (!value) return true;

  if (params instanceof Function) params = params(value, element, params);
  if (!params) return true;

  params = params instanceof Array ? params : [params];

  return !params.some(reg => !value.match(reg));
}, i18n.__INPUT_MATCH);

$.validator.addMethod("matchOne", function (value, element, params) {
  if (!value) return true;
  params = params instanceof Array ? params : [params];

  return params.some(reg => value.match(reg));
}, i18n.__INPUT_MATCH);



$.validator.addMethod("custom", function (value, element, params) {
  if (!value) return true;

  return params;
}, i18n.__INPUT_MATCH);