$(document).ready(() => {
  // Ingtech.socket = io();

  // Ingtech.socket.on('notify', (type, ...args) => {
  //   if (toastr[type]) {
  //     toastr[type](...args);
  //   }
  // });

  // Ingtech.socket.on('disconnect', console.log)

  let broker = Ingtech.Broker.scope('default');

  // broker.on('hi', (msg) => {
  //   console.log(msg)
  //   console.log('Hi from the server');

  //   // await util.sleep(10000)

  //   // console.log('finished')
  //   throw new Error('test')
  //   // ack(new Error('test'))
  // });

  broker.error('hi', (msg) => {
    console.log('error.hi from the server');
    console.log(msg)
  });
});