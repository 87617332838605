if (moment) {
	const oldMomentPrototypeLocal = moment.prototype.local;
	moment.prototype.local = function(keepLocalTime) {
		if (moment.defaultZone) {
			return this.tz(moment.defaultZone.name, keepLocalTime);
		} else {
			return oldMomentPrototypeLocal.call(this, keepLocalTime);
		}
	};

	const oldMomentTzGuess = moment.tz.guess;
	moment.tz.guess = function(ignoreCache) {
		if (moment.defaultZone) {
			return moment.defaultZone.name;
		} else {
			return oldMomentTzGuess.call(this, ignoreCache);
		}
	};
}